* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Register form subheading */
.formGroupTitle {
  color: white;
  position: absolute;
  top: -20px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  background: #101011;
}
.formGroupContainer {
  border: #333232 solid 1px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: 20px 40px;
}

@media (max-width: 450px) {
  .formGroupContainer {
    margin: 20px 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.dark-filepond .filepond--panel-root {
  background-color: #262b2b !important;
}
.dark-filepond .filepond--drop-label {
  color: #d4d0ca !important;
}

/* hide additional eye icon in ms edge */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* doc preview */
.pdf {
  width: 100%;
  aspect-ratio: 4 / 3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  box-shadow: inset 0 0 0 0 #23232329 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  overflow-x: auto;
}
